import { Injectable } from '@angular/core';
import {Game} from '../models/game';
import {Location} from '../models/location';
import {Player} from '../models/player';
import {Stat} from '../models/stat';
import {GamePlayer} from '../models/gameplayer';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrl = 'https://api.hoopreplays.com/api';
  headers = new HttpHeaders();
 
  constructor(private http: HttpClient) { 
    this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
  }



  accessCodePost(code:string): Observable<Game[]> {
    return this.http.post<Game[]>(`${this.apiUrl}/access/`,`"${code}"`,{headers:this.headers});
  }

  gamesFromLocation(location_Id:string): Observable<Game[]> {
    return this.http.get<Game[]>(`${this.apiUrl}/locations/${location_Id}/games`);
  }

  gamesGet(zipcode:string = '37064'): Observable<Game[]> {
    return this.http.get<Game[]>(`${this.apiUrl}/games?zipcode=${zipcode}`);
  }
  gameGet(id:number): Observable<Game> {
    return this.http.get<Game>(`${this.apiUrl}/games/${id}`).pipe(map((resp:any)=>{
      console.log("res",resp);
      return resp;
    }));
  }
  gamePost(game:Game): Observable<Game> {
    return this.http.post<Game>(`${this.apiUrl}/games/`,game);
  }
  gamePut(game:Game): Observable<Game> {
    return this.http.put<Game>(`${this.apiUrl}/games/${game.game_Id}`,game);
  }

  gameUpdatePlayersOnTeam(gameId:number,teamName:string, playersOnTeam:Player[]){
    return this.http.post<any>(`${this.apiUrl}/games/${gameId}/team/${teamName}/players`,playersOnTeam);
  }

  locationsGet(): Observable<Location[]> {
    return this.http.get<Location[]>(`${this.apiUrl}/locations`);
  }
  locationGet(id:number): Observable<Location> {
    return this.http.get<Location>(`${this.apiUrl}/locations/${id}`);
  }
  locationPost(location:Location): Observable<Location> {
    return this.http.post<Location>(`${this.apiUrl}/locations/`,location);
  }
  locationPut(location:Location): Observable<Location> {
    return this.http.put<Location>(`${this.apiUrl}/locations/${location.location_id}`,location);
  }

  playersGet(): Observable<Player[]> {
    return this.http.get<Player[]>(`${this.apiUrl}/players`);
  }
  

  playerPost(player:Player): Observable<Player> {
    return this.http.post<Player>(`${this.apiUrl}/players`,player);
  }

  statPost(stat:Stat): Observable<Stat> {
    return this.http.post<Stat>(`${this.apiUrl}/stats`,stat);
  }



}
